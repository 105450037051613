import kentFawcett from '../assets/images/kentFawcett.jpg'
import boysToMen from '../assets/images/boysToMen.jpg'
import tec from '../assets/images/travelExperienceCo.jpg'
import gm from '../assets/images/georgiaMentors.jpeg'
import hollies from '../assets/images/hollies.jpeg'
import angular from '../assets/images/angular.png'
import react from '../assets/images/react.webp'
import wp from '../assets/images/wordpress.png'

// eslint-disable-next-line import/no-anonymous-default-export
export default [
  {
    id: 1,
    img: hollies,
    imgAlt: 'Hollies',
    title: 'Hollies Playbook',
    theNeed:
      'A coach\'s team needed a playbook for access to instruction when not on the field. In-person practice just wasn\'t enough time to learn all the nuances of the game.',
    theSolution:
      'We designed a sleek, simple, interactive place in cyberspace for parents and players to view game schedule and field positioning.',
    link: 'https://www.holliesbop.com',
    icon: angular
  },
  {
    id: 2,
    img: tec,
    imgAlt: 'Travel Experience Co.',
    title: 'Travel Experience Co.',
    theNeed:
      'A client needs to upgrade their static, boring Wix website to a professional "I mean business" attitude website.',
    theSolution:
      'A professionally upgraded, fast-loading, eye-catching myriad of colorful designs that states "my business is legit".',
    link: 'https://www.travelexperienceco.com',
    icon: react
  },
  {
    id: 3,
    img: boysToMen,
    imgAlt: 'Boys To Men',
    title: 'Boys To Men',
    theNeed:
      'A local minister needs a website to inform parents of Douglas county youth about an afterschool program.  A website to distribute flyers as well as display photos of past events for parental peace of mind.',
    theSolution:
      'Bravo Echo donated a simple website plan with a photo carousel, pertinent program information, flyer download and rotating Bible verses that have special meaning to the program.',
    link: 'https://www.boystomen.faith',
    icon: react
  },
  {
    id: 4,
    img: gm,
    imgAlt: 'Georgia Mentors',
    title: 'Georgia Mentors',
    theNeed:
      'A business owner needs an updated, easy-to-navigate website to provide validity to her business.',
    theSolution:
      'Develop a minimalist layout design that provides all important business information in concise format, including client testimonials.',
    link: 'https://www.georgiamentors.com',
    icon: angular
  },
  {
    id: 5,
    img: kentFawcett,
    imgAlt: 'Kent Fawcett',
    title: 'Kent Fawcett',
    theNeed:
      'A salesperson needs a place to store catalogs and price lists for vendor customers to view and download',
    theSolution:
      'A classy blog-style website where vendors can retrieve information and also view the latest trends in home fashion.',
    link: 'http://www.kentfawcett.com',
    icon: wp
  }
]
