// eslint-disable-next-line no-undef, import/no-anonymous-default-export
export default [
  {
    id: 1,
    title: 'comet',
    totalCost: 995,
    monthlyCost: 89,
    updates: 4,
    includes: [
      'modern layout',
      'mobile-first design',
      'SEO integration',
      'domain registration',
      'file hosting',
      'browser testing',
    ],
    function: 'informational',
  },
  {
    id: 2,
    title: 'asteroid',
    totalCost: 1495,
    monthlyCost: 119,
    updates: 4,
    includes: [
      'everything Comet +',
      'contact form',
      'domain email',
      'competition monitoring',
    ],
    function: '+ contact',
  },
  {
    id: 3,
    title: 'planet',
    totalCost: 4995,
    monthlyCost: 439,
    updates: 'unlimited',
    includes: [
      'everything Asteroid +',
      'customer login',
      'database storage',
      'API connection for dynamic content',
      'startup marketing package included!',
    ],
    function: '+ backend',
  },
  {
    id: 4,
    title: 'galaxy',
    totalCost: 9995,
    monthlyCost: 789,
    updates: 'unlimited',
    includes: [
      'everything Planet +',
      'SSL certificate',
      'product management',
      'payment portal',
      'growth marketing package included!',
    ],
    function: '+ ecommerce',
  },
]

