export default [
  {
    id: 1,
    title: 'startup',
    cost: 99,
    video: 1,
    image: 1,
    logoAnimation: false,
    minViews: 100,
    report: 'monthly',
  },
  {
    id: 2,
    title: 'growth',
    cost: 299,
    video: 2,
    image: 2,
    logoAnimation: false,
    minViews: 350,
    report: 'monthly',
  },
  {
    id: 3,
    title: 'premium',
    cost: 599,
    video: 4,
    image: 2,
    logoAnimation: true,
    minViews: 800,
    report: 'weekly',
  },
  {
    id: 4,
    title: 'enterprise',
    cost: 899,
    video: 6,
    image: 3,
    logoAnimation: true,
    minViews: 1500,
    report: 'weekly',
  },
]
